# version 0.1.1

CameraFtr = null
### @ngInject ###
CameraFtr = ($cordovaCamera, $log) ->
    data =
        p1: ''
        p2: ''

    service =
        property: ''

        getPicture: (opts={}) ->
            options =
                quality: 75
                destinationType: 1 # 0: base64-encoded string | 1: file URI | 2: native URI
                sourceType: 1 # 0: PHOTOLIBRARY | 1: CAMERA | 2: SAVEDPHOTOALBUM
                allowEdit: false
                encodingType: 0 # 0: JPEG | 1: PNG
                targetWidth: 2560
                targetHeight: 2560
                mediaType: 0 # 0: PICTURE | 1: VIDEO | 2: ALLMEDIA
                #popoverOptions: CameraPopoverOptions # iOS only
                correctOrientation: true
                saveToPhotoAlbum: false
            options = angular.extend {}, options, opts
            $cordovaCamera.getPicture options

        getData: -> data
    service


angular.module 'app'
.factory 'CameraFtr', CameraFtr
